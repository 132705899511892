<div class="container">
  <h1>Book reading club signup</h1>
  <form>

    <div class="form-group">
      <label for="author">Name</label>
      <input data-e2e-id="name" type="text" class="form-control" id="author" required [(ngModel)]="model.name" name="author">
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input data-e2e-id="email" type="text" class="form-control" id="email" [(ngModel)]="model.email" name="email">
    </div>
    <div class="form-group">
      <label for="genre">Genre</label>
      <input data-e2e-id="genre" type="text" class="form-control" id="genre" required [(ngModel)]="model.genre" name="genre">
    </div>
    <button data-e2e-id="submit" type="input" (click)="submitSubscription()" class="btn btn-default">Submit</button>
  </form>

  <br>
  <div class="alert alert-success" *ngFor="let feedback of feedbackList.slice().reverse()">
    {{feedback.message}} {{feedback.time | date: "dd, mm, yyyy, HH:MM:ss" }}
  </div>
</div>
